import { classNames } from "../../Helpers"

export default function Button({ label, onClick, className, color = 'blue', size = 'base', Icon, iconPosition = 'left', type = "button", flat = false, iconClassName = {}, disabled = false, appendClasses = '' }) {

    /* classes include py-1.5 px-2.5 bg-yellow-600 bg-yellow-700 hover:bg-blue-200 hover:text-blue-800 */

    const rems = {
        xs: { edgeMargin: 0.5, innerMargin: 1.5, size: 3.5, px: 2.5, py: 1.5, text: 'xs' },
        sm: { edgeMargin: 0.5, innerMargin: 2, size: 4, px: 3, py: 2, text: 'sm' },
        base: { edgeMargin: 1, innerMargin: 2, size: 5, px: 4, py: 2, text: 'sm' },
        lg: { edgeMargin: 1, innerMargin: 3, size: 5, px: 4, py: 2, text: 'base' },
        xl: { edgeMargin: 1, innerMargin: 3, size: 5, px: 6, py: 3, text: 'base' },
    }

    const getIconClasses = () => {
        const edge = iconPosition === 'left' ? 'l' : 'r'
        const inner = iconPosition === 'left' ? 'r' : 'l'
        const defaults = `-m${edge}-${rems[size].edgeMargin} m${inner}-${rems[size].innerMargin} h-${rems[size].size} w-${rems[size].size}`
        return color === 'white' ? `text-gray-400 ${defaults} ${iconClassName}` : `${defaults} ${iconClassName}`
    }

    const getButtonClasses = () => {
        let whiteClasses = 'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-blue-500 shadow-sm'
        let colorClasses = ''

        if (!flat) {
            whiteClasses += ' shadow-sm'
            colorClasses = `shadow-sm border border-transparent text-white bg-${color}-${!disabled ? 600 : 200} hover:bg-${color}-${!disabled ? 700 : 200} focus:ring-${color}-500`
        } else {
            colorClasses = `border border-transparent text-${color}-700 hover:text-${color}-800 bg-${color}-100 hover:bg-${color}-200 focus:ring-${color}-500`
        }
        const sizeClasses = `px-${rems[size].px} py-${rems[size].py} text-${rems[size].text} rounded${size !== 'xs' ? '-md' : ''}`
        return `${color === 'white' ? whiteClasses : colorClasses} ${sizeClasses}`
    }

    return (
        <button type={type} className={classNames(className, getButtonClasses(),
            "inline-flex items-center justify-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2", appendClasses)}
            onClick={onClick}
            disabled={disabled}
        >
            {(Icon && iconPosition === 'left') && <Icon className={getIconClasses()} />}
            <span>{label}</span>
            {(Icon && iconPosition === 'right') && <Icon className={getIconClasses()} />}
        </button>
    )
}
